import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import {
    ApiService,
    JwtService,
    LocalStorageService,
    UtilsService,
    PatientsService,
    RolesRightsService,
    UserManagementService,
    FacilityService,
    InventoryService,
    SalesService,
    AgencyService,
    ReportsService
} from './services';
import { AuthGuard } from './guards';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        // services
        ApiService,
        AuthGuard,
        JwtService,
        LocalStorageService,
        UtilsService,
        PatientsService,
        RolesRightsService,
        UserManagementService,
        FacilityService,
        InventoryService,
        SalesService,
        AgencyService,
        ReportsService
    ],
    declarations: [],
    exports: []
})
export class CoreModule { }
