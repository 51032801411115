import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private _apiService: ApiService,
    private _http: HttpClient,

  ) { }

  triggerOnDemandEquipmentPickedUp(reportType, request) {
    const url = `${API_ENDPOINTS.REPORTS.REPORTS}?report_type=${reportType}`;
    return this._apiService.post(url, request);
  }

  generateDailyReport(startDate, endDate) {
    const url = `${API_ENDPOINTS.REPORTS.REPORTS_V2}?report_type=active-patient-count-report&startDate=${startDate}&endDate=${endDate}`;
    // const url = `https://stg-api.h3sdme.com/v2/reports?report_type=active-patient-count-report&startDate=2023-08-01&endDate=2023-10-31`;
    return this._http.get(url, {
      responseType: 'blob'
    });
  }
}