export const MODULES = {
    CURRENT_ORDER: 'Current Order',
    USER: 'User',
    ROLES_RIGHTS: 'Roles & Rights',
    INVENTORY: 'Inventory',
    PRODUCTS: 'Products',
    WARE_HOURSE: 'Ware House',
    SALES: 'Sales',
    ORDERS: 'Orders',
    INVOICES: 'Invoices',
    PATIENTS: 'Patients',
    AGENCY: 'Agency',
    FACILITY: 'Facility',
    EQUIPMENT: 'Equipment',
    REPORTS: 'Reports',
    RESOURCES: 'Resources'
};
