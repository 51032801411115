import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';

@Injectable()
export class AgencyService {

    constructor(
        private _apiService: ApiService,
    ) { }

    createAgency(params) {
        const url = `${API_ENDPOINTS.AGENCY}`;
        return this._apiService.post(url, params);
    }

    getAllAgency(size, pageNumber, searchParams) {
        const url = searchParams ? `${API_ENDPOINTS.AGENCY}?offset=${size}&page=${pageNumber}&searchString=${searchParams}`
            : `${API_ENDPOINTS.AGENCY}?offset=${size}&page=${pageNumber}`;
        return this._apiService.get(url);
    }

    getAgencyById(id) {
        const url = `${API_ENDPOINTS.AGENCY}/${id}`;
        return this._apiService.get(url);
    }

    deleteAgency(id) {
        const url = `${API_ENDPOINTS.AGENCY}/${id}`;
        return this._apiService.delete(url);
    }

    uploadContract(agencyId, params) {
        const url = `${API_ENDPOINTS.AGENCY_UPLOAD_CONTRACT}/${agencyId}`;
        return this._apiService.mulitipartForm(url, params);
    }

}
