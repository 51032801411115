
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found.component';

const NOT_FOUND_ROUTES: Routes = [
    {
        path: 'not-found',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(NOT_FOUND_ROUTES)],
    exports: [RouterModule]
})
export class NotFoundRoutingModule {}
