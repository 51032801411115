import { Injectable } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview';
import * as _ from 'lodash';

import { ApiService } from './api.service';
import { API_ENDPOINTS, ROLES, ROUTES } from '../constants';
import { OPERATION_TYPES } from '../constants/permission.constant';

@Injectable({
    providedIn: 'root'
})

export class RolesRightsService {

    createRoles = this.getRoleTrees(ROLES, 1000);

    constructor(
        private _apiService: ApiService,
    ) { }

    addRoleRight(params) {
        const url = `${API_ENDPOINTS.USER_TYPE}`;
        return this._apiService.post(url, params);
    }

    getRoleRight(size, pageNumber) {
        const url = `${API_ENDPOINTS.USER_TYPE}?offset=${size}&page=${pageNumber}`;
        return this._apiService.get(url);
    }

    getRoleId(size, pageNumber, id, searchParams) {
        const url = searchParams ? `${API_ENDPOINTS.USER_BY_ROLE}/${id}?offset=${size}&page=${pageNumber}&searchString=${searchParams}`
            : `${API_ENDPOINTS.USER_BY_ROLE}/${id}?offset=${size}&page=${pageNumber}`;
        return this._apiService.get(url);
    }

    editRoleRight() {
        const url = `${API_ENDPOINTS.USER_TYPE}`;
        return this._apiService.post(url);
    }

    deleteRole(id) {
        const url = `${API_ENDPOINTS.USER_TYPE}/${id}`;
        return this._apiService.delete(url);
    }

    getCreatedRoels() {
        return [...this.createRoles];
    }

    getRoleTrees(roles, multiplier) {
        let counter = 0;
        const result = [];
        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i];
            role.key = this.getCamelCase(role.text);
            counter += 1;
            role.value = counter + multiplier;
            if (role.hasOwnProperty('children') && Array.isArray(role.children)) {
                role.children = this.getRoleTrees(role.children, role.value * multiplier);
            }
            result.push({ ...role });
        }
        return result;
    }

    getCamelCase(str) {
        if (str) {
            str = str.replace(/[^a-zA-Z ]/g, '');
            return _.camelCase(str);
        }
        return null;
    }

    createTreeViewItem(data) {
        const tempArr = [];
        for (let i = 0, len = data.length; i < len; i++) {
            tempArr.push(new TreeviewItem(data[i]));
        }
        return tempArr;
    }

    getPermissionByModule(tree, moduleName) {
        const operations = Object.keys(OPERATION_TYPES);
        const operationsVal = Object.values(OPERATION_TYPES);
        const PERMISSIONS = {};
        operations.forEach(function (op) {
            PERMISSIONS[op] = false;
        });

        for (let i = 0, len = tree.length; i < len; i++) {
            const role = tree[i];
            if (role.text === moduleName) {
                // check if any children text is matching with operation type
                let flag = false;
                role.children.forEach(function (child) {
                    const index = operationsVal.indexOf(child.text);
                    if (index > -1) {
                        PERMISSIONS[operations[index]] = child['checked'];
                        flag = true;
                    }
                });

                if (!flag) { // optional -> set all permissions to true
                    for (const key in PERMISSIONS) {
                        if (key) {
                            PERMISSIONS[key] = true;
                        }
                    }
                }

                break;
            } else {
                if (role.hasOwnProperty('children') && Array.isArray(role.children)) {
                    const roleObj = this.getRoleObj(role.children, moduleName);
                    if (roleObj) {
                        return this.getPermissionByModule([roleObj], moduleName);
                    }
                }
            }

        }

        return {
            PERMISSIONS: PERMISSIONS
        };
    }

    getRoleObj(tree, moduleName) {
        let result = null;
        for (let i = 0, len = tree.length; i < len; i++) {
            const role = tree[i];
            if (role.text === moduleName) {
                result = role;
                break;
            } else if (role.hasOwnProperty('children') && Array.isArray(role.children)) {
                result = this.getRoleObj(role.children, moduleName);
                if (result) {
                    break;
                }
            }
        }
        return result;
    }

    checkSubChild(data) {
        let flag = false;
        for (let i = 0, len = data.length; i < len; i++) {
            if (data[i].hasOwnProperty('children') && Array.isArray(data[i].children)) {
                flag = true;
                break;
            }
        }
        return flag;
    }

    changePermissionFlag(data, value) {
        for (let i = 0, len = data.length; i < len; i++) {
            if (value && value.length && value.indexOf(data[i]['value']) > -1) {
                data[i]['checked'] = true;
            } else {
                data[i]['checked'] = false;
            }
        }
        return data;
    }

    selectedTree(roles, permissions?) {
        const result = [];
        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i];
            const checkData = this.checkSubChild(role['children']);
            if (checkData) {
                this.selectedTree(role['children'], permissions);
            } else {
                role['children'] = [...this.changePermissionFlag(role['children'], permissions)];
            }
            result.push(role);
        }
        return result;
    }

    getAuthorisedModules(roles, permissions?) {
        const result = [];
        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i];
            result.push(this.checkRoutes(role, permissions));
        }
        return result;
    }

    checkRoutes(roleValues, permissions) {
        if (roleValues.children && roleValues.children.length) {
            for (let i = 0, len = roleValues.children.length; i < len; i++) {
                if (roleValues.children[i].hasOwnProperty('children') && Array.isArray(roleValues.children[i].children)) {
                    const roleName = this.checkRoutes(roleValues.children[i], permissions);
                    return roleName;
                } else {
                    if (permissions && permissions.indexOf(roleValues.children[i].value) > -1) {
                        return roleValues.text;
                    }
                }
            }
        }
    }

    getAuthorisedRoutes(routesArray) {
        const routes = [];
        for (let i = 0, len = routesArray.length; i < len; i++) {
            for (let j = 0, len2 = ROUTES.length; j < len2; j++) {
                if (routesArray[i] === ROUTES[j].moduleName) {
                    routes.push(...ROUTES[j].routes);
                }
            }
        }
        return routes;
    }

}


