import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { LITERALS } from 'src/app/core/constants';
import { AuthService, UtilsService } from 'src/app/core/services';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    resetFormErrors = LITERALS.RESETPASSWORD.ERROR_MESSAGES;
    resetForm: FormGroup;
    token;
    spinnerFlag = false;

    constructor(
        private authService: AuthService,
        private router: ActivatedRoute,
        private formBuilder: FormBuilder,
        private utilsService: UtilsService,
        private _router: Router,
        private toastr: ToastrService
    ) {
        this.resetForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
            conformPassword: ['', Validators.required]
        }, {
                validator: this.confirmPassword
            });
    }

    ngOnInit() {
        this.router.params.forEach((urlParameters) => {
            this.token = urlParameters.token;
        });
        this.verifyToken();
    }

    verifyToken() {
        const param = {
            reset_token: this.token
        };
        this.authService.varifyResetToken(param).subscribe((res) => {
            if (res.status === '200') {
                // verified token
            } else {
                this._router.navigate(['/login']);
                this.toastr.error(res.message);
            }
        }, (err) => {
            this.toastr.error(err);
        });
    }

    onSubmit() {
        if (!this.resetForm.valid) {
            this.utilsService.validateAllFormFields(this.resetForm);
            return;
        }
        this.spinnerFlag = true;
        const param = {
            reset_token: this.token,
            password: this.resetForm.value.password
        };
        this.authService.resetPassword(param).subscribe(res => {
            this.spinnerFlag = false;
            if (res.status === '200') {
                this._router.navigate(['/login']);
                this.toastr.success('Your Password has been reset successfully.');
            } else {
                this.toastr.error(res.message);
            }
        }, (err) => {
            this.spinnerFlag = false;
            this.toastr.error(err);
        });
        this.resetForm.reset();
    }

    get f() {
        return this.resetForm.controls;
    }

    confirmPassword(control: AbstractControl) {
        const password = control.get('password').value;
        const conformPassword = control.get('conformPassword').value;
        if (password && conformPassword) {
            if (password !== conformPassword) {
                control.get('conformPassword').setErrors({
                    MatchPassword: true
                });
            } else {
                control.get('conformPassword').setErrors(null);
            }
        }
        return;
    }
}
