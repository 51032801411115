export const API_ENDPOINTS = {
    LOGIN: 'authentication',
    USER_MANAGEMENT: 'user',
    FORGOT_PASSWORD: 'forgotPassword',
    CUREENT_ORDER: 'currentorder',
    COMPLETE_OREDR: 'get-complete-order',
    UPDATE_PASSWORD: 'password/update',
    USER_TYPE: 'user_type',
    USER: 'users',
    PATIENT: {
        PATIENT: 'patient',
        EQUIPMENT_BY_PATIENT: 'equipmentbypatient',
        ORDER_HISTORY: 'orderhistory',
        ACTIVE_PATIENT_COUNT: 'active-patients-count',
        ACTIVE_HOME_HEALTH_PATIENT_COUNT: 'home-health-active-patients-count',
        VALIDATE_PATIENT: 'validate/patient',

    },
    USER_BY_ROLE: 'userbyrole',
    FACILITY: 'facility',
    FACILITYBYTYPE: 'facilityByType',
    INVENTORY: {
        SUB_CATEGORY: 'equipment_sub_category',
        ADD_EQUIPMENT: 'equipment',
        WAREHOUSE: 'warehouselocation',
        FILE_UPLOAD: 'upload',
        EQUIPMENT_LIST: 'equipment-list'
    },
    AGENCY: 'agency',
    AGENCY_UPLOAD_CONTRACT: 'upload',
    VERIFYRESETTOKEN: 'verifyToken',
    RESET_PASSWORD: 'resetPassword',
    SALES: {
        OREDR: 'order',
        UPDATE_ORDER: 'currentorder',
        CANCEL_ORDER: 'ordercancelled',
        INVOICE: 'invoicelist',
        INVOICE_PAYMENT_TYPE: 'invoice',
        INVOICE_DETAILS: 'invoicedetail',
        INVOICE_DOWNLOAD: 'invoice',
        ON_DEMAND_INVOICE: 'generateInvoice'
    },
    DRIVER: 'driverlist',
    ASSIGN_DRIVER: 'assigndriver',
    EQUIPMENT: {
        SCAN: 'equipment-detail',
        SEARCH_SERIAL_NUMBER_HISTORY: 'serialNumberHistory',
    },
    ORDER: {
        ORDER: 'order',
        COMPLETE_ORDER: 'complete-order',
        CANCELLED_ORDER: 'ordercancelled',
        RESCHEDULE_ORDER: 'orderreschedule',
        ADD_PICKUP_TICKET: '/order/pickupTicket/_add',
        UPLOAD_TICKET: 'upload-ticket',
        ADD_ADDITIONAL_COMMENTS: '/order/additionalComment/_add',
        PICKUP_TICKET: '/order/pickupTicket/',
        ADDITIONAL_COMMENTS: '/order/additionalComment/',
        ADDITIONAL_COMMENTS_STATUS: '/read/additionalComment/',
        VALIDATE_ORDER: '/order/validate',
        VALIDATE_ADDRESS: '/validate/address-flag',
    },
    REPORTS: {
        REPORTS: 'reports',
        REPORTS_V2: 'v2/reports',
    },
    RESOURCES: {
        RESOURCE: 'resource',
        UPLOAD: 'resource/upload',
        POSTDATA: 'resource/add-update',
        GET_RESOURCE: 'resource/get-all',
        DELETE_RESOURCE: 'resource/delete',
    }
};

