import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-acknowledge-dialog',
  templateUrl: './acknowledge-dialog.component.html',
  styleUrls: ['./acknowledge-dialog.component.css']
})
export class AcknowledgeDialogComponent implements OnInit {

  acknowledgeChecked: boolean = false
  error: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AcknowledgeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close('close')
  }

  proceed() {
    this.dialogRef.close('ok')
  }

}
