import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
// import { TooltipModule } from 'ng2-tooltip-directive';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { FullLayoutComponent } from './shared/layout/layout.component';
import { HeaderComponent, SidebarComponent } from './shared/layout';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReqInterceptor } from './core/interceptors/req-interceptor.service';
import { SidebarService } from './core/services/sidebar.service';
import { PatientContactCardComponent } from './shared/components/patient-contact-card/patient-contact-card.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatDialogModule } from '@angular/material/dialog';

const APP_CONTAINERS = [FullLayoutComponent];
const APP_COMPONENTS = [HeaderComponent, SidebarComponent];

@NgModule({
    imports: [
        BrowserModule,
        LandingPageModule,
        AuthModule,
        AppRoutingModule,
        SharedModule,
        CoreModule,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        TextMaskModule,
        // TooltipModule,
        NgxMaskModule.forRoot(),
        GooglePlaceModule,
        MatDialogModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        ...APP_COMPONENTS,
        PatientContactCardComponent
    ],
    providers: [
        SidebarService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ReqInterceptor, multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
