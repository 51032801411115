import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';


@Injectable({
    providedIn: 'root'
})
export class FacilityService {

    constructor(
        private _apiService: ApiService
    ) { }

    getAllFacility(size, pageNumber, searchParams) {
        const url = searchParams ? `${API_ENDPOINTS.FACILITY}?offset=${size}&page=${pageNumber}&searchString=${searchParams}`
            : `${API_ENDPOINTS.FACILITY}?offset=${size}&page=${pageNumber}`;
        return this._apiService.get(url);
    }

    addFacility(params) {
        const url = `${API_ENDPOINTS.FACILITY}`;
        return this._apiService.post(url, params);
    }

    deleteFacility(id) {
        const url = `${API_ENDPOINTS.FACILITY}/${id}`;
        return this._apiService.delete(url);
    }

    facilityByType(type) {
        const url = `${API_ENDPOINTS.FACILITYBYTYPE}?type=${type}`;
        return this._apiService.get(url);
    }
}

