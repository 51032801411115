import { LandingPageComponent } from './landing-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const LANDING_PAGE_ROUTES: Routes = [
    {
        path: '',
        component: LandingPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(LANDING_PAGE_ROUTES)],
    exports: [RouterModule]
})
export class LandingPageRoutingModule {}
