import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from './../constants/api-endpoints.constant';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private _apiService: ApiService,
  ) { }

  addEquipment(params) {
    const url = `${API_ENDPOINTS.INVENTORY.ADD_EQUIPMENT}`;
    return this._apiService.post(url, params);
  }

  getEquipment(size, pageNumber, searchParams) {
    const url = searchParams ? `${API_ENDPOINTS.INVENTORY.ADD_EQUIPMENT}?offset=${size}&page=${pageNumber}&searchString=${searchParams}`
      : `${API_ENDPOINTS.INVENTORY.ADD_EQUIPMENT}?offset=${size}&page=${pageNumber}`;
    return this._apiService.get(url);
  }

  updateEquipment(params) {
    const url = `${API_ENDPOINTS.INVENTORY.ADD_EQUIPMENT}`;
    return this._apiService.post(url, params);
  }

  addWarehouse(params) {
    const url = `${API_ENDPOINTS.INVENTORY.WAREHOUSE}`;
    return this._apiService.post(url, params);
  }

  getWarehouse(size, pageNumber) {
    const url = `${API_ENDPOINTS.INVENTORY.WAREHOUSE}?offset=${size}&page=${pageNumber}`;
    return this._apiService.get(url);
  }

  updateWarehouse(params) {
    const url = `${API_ENDPOINTS.INVENTORY.WAREHOUSE}`;
    return this._apiService.post(url, params);
  }

  uploadExcelFile(params) {
    const url = `${API_ENDPOINTS.INVENTORY.FILE_UPLOAD}`;
    return this._apiService.mulitipartForm(url, params);
  }

  getEquipmentList(agencyId, params) {
    const url = `${API_ENDPOINTS.INVENTORY.EQUIPMENT_LIST}?agency_id=${agencyId}&category=${encodeURIComponent(params)}`;
    return this._apiService.get(url);
  }
}
