import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './../services/auth.service';
import { LOCAL_STORAGE_VARIABLES } from '../constants';
import { LocalStorageService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _localStorageService: LocalStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (this._authService.isLoggednIn()) {
            const userDetails = this._localStorageService.getItem(LOCAL_STORAGE_VARIABLES.USER_DETAILS);
            const routes = JSON.parse(userDetails.usertype.routes);

            for (let i = 0, len = routes.length; i < len; i++) {
                if (state.url.indexOf(routes[i]) > -1) {
                    return true;
                }
            }
            this._router.navigate(['/current-order']);
            return true;
        }
        this._router.navigate(['/']);
        return false;
    }
}
