import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LandingPageRoutingModule } from './landing-page.routes';
import { LandingPageComponent } from './landing-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SafePipe } from '../core/pipe/safepipe.pipe';

@NgModule({
    imports: [
        CommonModule,
        LandingPageRoutingModule,
        NgbModule,
        CarouselModule.forRoot()
    ],
    declarations: [
        LandingPageComponent,
        SafePipe
    ]
})
export class LandingPageModule { }
