import { NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { CoreModule } from '../core/core.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';

@NgModule({
    imports: [
        SharedModule,
        AuthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        CommonModule
    ],
    declarations: [
        ForgotPasswordComponent,
        ResetPasswordComponent,
        LoginComponent
    ],
    providers: [
        DatePipe
    ]
})

export class AuthModule { }
