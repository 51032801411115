import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FullLayoutComponent } from './shared/layout/layout.component';

export const routes: Routes = [
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: 'agency-registration',
                loadChildren: './agency-registration/agency-registration.module#AgencyRegistrationModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'patients-list',
                loadChildren: './patients-list/patients-list.module#PatientsListModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'create-order',
                loadChildren: './create-order/create-order.module#CreateOrderModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'user-management',
                loadChildren: './user-management/user-management.module#UserManagementModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'roles-rights',
                loadChildren: './roles-rights/roles-rights.module#UserManagementModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'facility',
                loadChildren: './facility/facility.module#FacilityModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'inventory',
                loadChildren: './inventory/inventory.module#InventoryModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'current-order',
                loadChildren: './current-order/current-order.module#CurrentOrderModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'sales',
                loadChildren: './sales/sales.module#SalesModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'equipment-search',
                loadChildren: './equipment-search/equipment-search.module#EquipmentSearchModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'reports',
                loadChildren: './reports/reports.module#ReportsModule',
                canActivate: [AuthGuard]
            }, {
                path: 'resources',
                loadChildren: './resources/resource.module#ResourceModule',
                canActivate: [AuthGuard]
            }
        ]
    },
    { path: '**', redirectTo: '/not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes), NgbModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
