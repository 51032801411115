import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { LOCAL_STORAGE_VARIABLES } from '../constants';

@Injectable()
export class JwtService {

    constructor(
        private _localStorageService: LocalStorageService
    ) { }

    getToken(): String {
        return this._localStorageService.getItem(LOCAL_STORAGE_VARIABLES.TOKEN);
    }

    setToken(token: String) {
        this._localStorageService.setItem(LOCAL_STORAGE_VARIABLES.TOKEN, token);
    }

    destroyToken() {
        this._localStorageService.removeItem(LOCAL_STORAGE_VARIABLES.TOKEN);
    }
}
