import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { REGEX_PATTERNS, LITERALS } from 'src/app/core/constants';
import { AuthService, UtilsService } from 'src/app/core/services';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    forgotPassword: FormGroup;
    forgotPasswordError = LITERALS.FORGOTPASSWORD.ERROR_MESSAGES;
    spinnerFlag = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private _router: Router,
        private toastr: ToastrService,
        private utilsService: UtilsService
    ) {
        this.forgotPassword = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.WHITESPACE)]],
        });
    }

    ngOnInit() { }

    get f() {
        return this.forgotPassword.controls;
    }

    onSubmit() {
        if (!this.forgotPassword.valid) {
            this.utilsService.validateAllFormFields(this.forgotPassword);
            return;
        }
        this.spinnerFlag = true;
        const param = {
            username: this.forgotPassword.value.username,
            url: `${window.location.origin}/reset-password`
        };
        this.authService.forgotPassword(param).subscribe(res => {
            this.spinnerFlag = false;
            if (res.status === '200') {
                this._router.navigate(['/login']);
                this.toastr.success('Email has been sent for the provided user name.');
            } else {
                this.toastr.error(res.message);
            }
        }, (err) => {
            this.spinnerFlag = false;
            this.toastr.error(err);
        });
    }
}
