import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JwtService } from './../services/jwt.service';

@Injectable()
export class ReqInterceptor implements HttpInterceptor {

    setHeaders() {
        const token = this._jwtService.getToken();
        const params = {};
        if (token) {
            params['authToken'] = token;
            const headers: HttpHeaders = new HttpHeaders(params);
            return headers;
        }
        return;
    }

    constructor(
        private _jwtService: JwtService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.setHeaders() ||
            req.url.search('https://www.linkedin.com/oauth/v2/accessToken') !== -1) {
            return next.handle(req);
        } else {
            const modifiedReq = req.clone({ headers: this.setHeaders() });
            return next.handle(modifiedReq);
        }
    }
}
