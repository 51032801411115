export const LITERALS = {
    AUTH: {
        ERROR_MESSAGES: {
            USERNAME_REQUIRED: 'Username is required.',
            USERNAME_VALID: 'Username is not valid.',
            FIRST_NAME_REQUIRED: 'Firstname is required.',
            LAST_NAME_REQUIRED: 'Lastname is required.',
            EMAIL_REQUIRED: 'Email Address is required.',
            EMAIL_VALID: 'Email Address is not valid.',
            PHONE_REQUIRED: 'Mobile number is required.',
            PHONE_VALID: 'Mobile number is not valid.',
            PASSWORD_REQUIRED: 'Password is required.',
            CURRENT_PASSWORD_REQUIRED: 'Old password is required.',
            CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required.',
            CONFIRM_PASSWORD_SAME_REQUIRED: 'Password and confirm Password must be same.',
            PASSWORD_VALID: 'Password must have minimum 8 characters, 1 uppercase, 1 number, 1 special character.',
            CATEGORY_REQUIRED: 'Category is required.',
            INFLUENCER_REQUIRED: 'Influencer type is required.',
            INTERESTS_REQUIRED: 'Interests are required.',
            STATE_REQUIRED: 'State is required.',
            CITY_REQUIRED: 'City is required.',
            DOB_REQUIRED: 'Date of birth is required.',
            COMPANY_NAME_REQUIRED: 'Company name required.',
            TERMS_AND_CONDITION_REQUIRED: 'Please accept Privacy Policy and Terms & Conditions to proceed.',
            PASSWORD_MINLENGTH: 'Password must be at least 8 characters long.',
            PASSWORD_MAXLENGTH: 'Password can be maximum 20 characters long.',
        },
        SUCCESS_MESSAGES: {}
    },
    AGENCY: {
        ERROR_MESSAGES: {
            AGENCY_NAME_REQUIRED: 'Agency Name is required.',
            AGENCY_NPS_REQUIRED: 'NPS Number is required.',
            EMAIL_REQUIRED: 'Email Address is required.',
            EMAIL_VALID: 'Email Address is not valid.',
            FAX_REQUIRED: 'Fax number is required.',
            MOBILE_REQUIRED: 'Phone Number is required.',
            MOBILE_VALID: 'Phone Number is not valid.',
            ADDRESS_REQUIRED: 'Address is required.',
            ZIPCODE_REQUIRED: 'Zipcode is required.',
            STATE_REQUIRED: 'State is required.',
            ZIPCODE_VALID: 'Zipcode is not valid.',
            CITY_REQUIRED: 'City is required.',
            FILE_UPLOAD: 'Only png / jpeg / pdf / doc / excel files are supported.'
        }
    },
    PATIENTS: {
        ERROR_MESSAGES: {
            FIRST_NAME_REQUIRED: 'Firstname is required.',
            FIRST_NAME_VALID: 'Firstname is not valid.',
            LAST_NAME_REQUIRED: 'Lastname is required.',
            LAST_NAME_VALID: 'Lastname is not valid.',
            FACILITY_NAME_REQUIRED: 'Facility name is required.',
            DOB_REQUIRED: 'Date of birth is required.',
            DOB_VALID: 'Date format not valid.(e.g. MM-DD-YYYY)',
            ADDRESS_REQUIRED: 'Address is required.',
            CITY_REQUIRED: 'City is required.',
            STATE_REQUIRED: 'State is required.',
            ZIPCODE_REQUIRED: 'Zipcode is required.',
            ZIPCODE_VALID: 'Zipcode is not valid.',
            PHONE_REQUIRED: 'Phone Number is required.',
            PHONE_VALID: 'Phone Number is not valid.',
            EMERGRNCY_FIRSTNAME_REQUIRED: 'Emergency contact Firstname is required.',
            EMERGRNCY_FIRSTNAME_VALID: 'Emergency contact Firstname is not valid.',
            EMERGRNCY_LASTNAME_REQUIRED: 'Emergency contact Lastname is required.',
            EMERGRNCY_LASTNAME_VALID: 'Emergency contact Lastname is not valid.',
            EMERGRNCY_PRIMARY_CONTACT_REQUIRED: 'Emergency contact primary number is required.',
            EMERGRNCY_PRIMARY_CONTACT_VALID: 'Emergency contact primary number is not valid.',
            AGENCY_NAME_REQUIRED: 'Agency Name is required.',
            FLOW_RATE_REQUIRED: 'Flow Rate is required.',
            FLOW_RATE_VALID: 'Flow Rate is not valid.'
        }
    },
    USERS: {
        ERROR_MESSAGES: {
            FIRST_NAME_REQUIRED: 'Firstname is required.',
            FIRST_NAME_VALID: 'Firstname must contain characters only.',
            LAST_NAME_REQUIRED: 'Lastname is required.',
            LAST_NAME_VALID: 'Lastname must contain characters only.',
            USERNAME_REQUIRED: 'Username is required.',
            USERNAME_VALID: 'Username must contain characters only.',
            EMAIL_REQUIRED: 'Email Address is required.',
            EMAIL_VALID: 'Email Address is not valid.',
            ROLE_REQUIRED: 'Role Name is required.',
            AGENCY_REQUIRED: 'Agency Name is required.',
            PHONE_REQUIRED: 'Phone Number is required.',
            ADDRESS_REQUIRED: 'Address is required.',
            PHONE_VALID: 'Phone Number is not valid.'
        }
    },
    ROLESRIGHTS: {
        ERROR_MESSAGES: {
            NAME_REQUIRED: 'Role Name is required.',
            NAME_VALID: 'Role Name must contain characters only.',
            USER_TYPE: 'User Type is required.'
        }
    },
    INVENTORY: {
        ERROR_MESSAGES: {
            EQUIPMENT_NAME: 'Equipment Name is required.',
            PRODUCT_CODE: 'Product Code is required.',
            CATEGORY: 'Category is required.',
            SUB_CATEGORY: 'Sub Category is required.',
            MANUFACTURE_NAME: 'Manufacture Name is required.',
            PRICE: 'Price is required.',
            PRICE_VALID: 'Price is not valid.',
            ADDITIONAL_PRICE: 'Additional Price is required.',
            ADDITIONAL_PRICE_VALID: 'Additional Price is not valid.',
            QUANTITY: 'Quantity is required.',
            QUANTITY_VALID: 'Quantity is not valid.',
            FILE_UPLOAD: 'Only excel file supported.'
        }
    },
    WAREHOUSE: {
        ERROR_MESSAGES: {
            WAREHOUSE_NAME: 'Warehouse Name is required.',
            WAREHOUSE_ADDRESS: 'Warehouse Address is required.',
            WAREHOUSE_PHONENO: 'Warehouse Phone No. is required.',
            WAREHOUSE_PHONENO_VALID: 'Warehouse Phone No. is not valid.'
        }
    },
    FICILITY: {
        ERROR_MESSAGES: {
            FICILITY_TYPE: 'Facility Type is required.',
            NAME_REQUIRED: 'Name is required.',
            CITY_REQUIRED: 'City is required.',
            STATE_REQUIRED: 'State is required.',
            ZIPCODE_REQUIRED: 'Zipcode is required.',
            ZIPCODE_VALID: 'Zipcode is not valid.',
            PHONE_REQUIRED: 'Phone number is required.',
            PHONE_VALID: 'Phone number is not valid.',
            ADDRESS_REQUIRED: 'Address is required.'
        }
    },
    SERIAL_NUMBER_ASSIGNMENT: {
        ERROR_MESSAGES: {
           SERIAL_NUMBER_REQUIRED: 'Serial number is required.',
           SERIAL_NUMBER_VALID: 'Serial number is not valid.',
        }
    },
    FORGOTPASSWORD: {
        ERROR_MESSAGES: {
            USER_NAME_REQUIRED: 'User name is required.',
            USER_NAME_VALID: 'User name is not valid.',
        },
    },
    RESETPASSWORD: {
        ERROR_MESSAGES: {
            PASSWORD_REQUIRED: ' New Password is required.',
            CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required.',
            PASSWORD_MINLENGTH: ' New Password must be at least 8 characters long.',
            PASSWORD_MAXLENGTH: 'New Password can be maximum 20 characters long.',
            CONFIRM_PASSWORD_MATCH: 'Confirm Password and  New Password must be same.',
        },
    },

    SALES: {
        DILEVERY: {
            ERROR_MESSAGES: {
                FUTURE_EXCHANGE_TIME_RANGE_REQUIRED: 'Future exchange time range is required.',
                ORDERING_NURSE: 'Ordering Nurse is required.',
                PHONE_REQUIRED: 'Phone number is required.',
                PHONE_VALID: 'Phone number is not valid.'
            }
        },
        EXCHANGE: {
            ERROR_MESSAGES: {
                FUTURE_EXCHANGE_TIME_RANGE_REQUIRED: 'Future exchange time range is required.',
                EXCHANGE_REASON_REQUIRED: 'Exchange Reason is required.',
                ORDERING_NURSE: 'Ordering Nurse is required.',
                PHONE_REQUIRED: 'Phone number is required.',
                PHONE_VALID: 'Phone number is not valid.'
            }
        },
        PICKUP: {
            ERROR_MESSAGES: {
                FUTURE_EXCHANGE_TIME_RANGE_REQUIRED: 'Future exchange time range is required.',
                PICKUP_REASON_REQUIRED: 'Pickup reason is required.',
                ORDERING_NURSE: 'Ordering Nurse is required.',
                PHONE_REQUIRED: 'Phone number is required.',
                PHONE_VALID: 'Phone number is not valid.'
            }
        },
        MOVE: {
            ERROR_MESSAGES: {
                FUTURE_EXCHANGE_TIME_RANGE_REQUIRED: 'Future exchange time range is required.',
                ORDERING_NURSE: 'Ordering Nurse is required.',
                PHONE_REQUIRED: 'Phone number is required.',
                PHONE_VALID: 'Phone number is not valid.'
            }
        },
        ERROR_MESSAGES: {
            AMOUNT_REQUIRED: 'Amount is required.',
            AMOUNT_VALID: ' Amount is not valid'
        },
        RESPITE: {
            ERROR_MESSAGES: {
                DELIVERY_REQUIRED: 'Delivery Date is required.',
                PICKUP_REQUIRED: 'Pick-Up Date is required.',
                PICKUP_TIME_REQUIRED: 'Pick-Up Time is required.',
                RESPITE_LOCATION_TYPE: 'Location Type is Mandatory.',
                RESPITE_FACILITY_NAME: 'Pick-Up Respite Facility.',
                ORDERING_NURSE: 'Ordering Nurse is required.',
                PHONE_REQUIRED: 'Phone number is required.',
                PHONE_VALID: 'Phone number is not valid.'
            }
        },
    }
};


