import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { Login } from '../models/auth';
import { LocalStorageService } from './local-storage.service';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private _apiService: ApiService,
        private _jwtService: JwtService,
        private _localStorageService: LocalStorageService
    ) { }

    private signupDataSource = new BehaviorSubject('');
    signupDataChange$ = this.signupDataSource.asObservable();

    login(params: Login): Observable<Login> {
        const url = `${API_ENDPOINTS.LOGIN}`;
        return this._apiService.post(url, params);
    }

    logout() {
        this._jwtService.destroyToken();
        this._localStorageService.clearAll();
    }

    isLoggednIn() {
        return this._jwtService.getToken() ? true : false;
    }

    forgotPassword(params) {
        const url = `${API_ENDPOINTS.FORGOT_PASSWORD}`;
        return this._apiService.post(url, params);
    }

    varifyResetToken(params) {
        const url = `${API_ENDPOINTS.VERIFYRESETTOKEN}`;
        return this._apiService.post(url, params);
    }

    resetPassword(params) {
        const url = `${API_ENDPOINTS.RESET_PASSWORD}`;
        return this._apiService.post(url, params);
    }
}
