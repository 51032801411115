import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { SidebarService, RolesRightsService, LocalStorageService } from 'src/app/core/services';
import { LOCAL_STORAGE_VARIABLES, ROLES } from 'src/app/core/constants';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    isOpen = false;
    roles;
    sidebarMenus: any;
    userDetails;
    svg: SafeHtml;

    constructor(
        private _sidebarService: SidebarService,
        private rolesRightsService: RolesRightsService,
        private _localStorageService: LocalStorageService,
        private toastr: ToastrService,
        public sanitizer: DomSanitizer
    ) {
        this._sidebarService.watchToggleSideNav().subscribe(() => {
            this.isOpen = !this.isOpen;
        }, (err) => {
            this.toastr.error(err);
        });
        this.sidebarMenus = [];
    }

    ngOnInit() {
        this.userDetails = this._localStorageService.getItem(LOCAL_STORAGE_VARIABLES.USER_DETAILS);
        this.roles = this.rolesRightsService.getRoleTrees(ROLES, 1000);
        this.roles = this.rolesRightsService.selectedTree(this.roles, JSON.parse(this.userDetails.usertype.permission));
        this.manageSidebarMenu();
    }

    getModuleSvg(moduleName) {
        for (let i = 0, len = this.roles.length; i < len; i++) {
            if (this.roles[i].text === moduleName) {
                return this.sanitizer.bypassSecurityTrustHtml(this.roles[i].icon);
            }
        }
        return;
    }

    manageSidebarMenu() {
        for (let i = 0, len = this.roles.length; i < len; i++) {

            const childArray = this.roles[i]['children'];
            if (this.checkRolePermission(childArray)) {
                const tempObj = {};
                tempObj['id'] = this.roles[i].value;
                tempObj['label'] = this.roles[i].text;
                tempObj['routerLink'] = this.roles[i].navigationLink;
                tempObj['icon'] = this.roles[i].icon;
                this.sidebarMenus.push(tempObj);
            }
        }
    }

    checkRolePermission(childArray) {
        for (let i = 0, len = childArray.length; i < len; i++) {
            if (childArray[i].children && childArray[i].children.length) {
                for (let j = 0, len1 = childArray[i].children.length; j < len1; j++) {
                    if (childArray[i].children[j].checked) {
                        return true;
                    }
                }
            } else {
                if (childArray[i].checked) {
                    return true;
                }
            }
        }
        return false;
    }
}
