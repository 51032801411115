import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { LOCAL_STORAGE_VARIABLES } from 'src/app/core/constants';
import { SidebarService, LocalStorageService } from 'src/app/core/services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

    userDetails;
    spinnerFlag = false;

    constructor(
        private _sidebarService: SidebarService,
        private _router: Router,
        private _localStorageService: LocalStorageService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.userDetails = this._localStorageService.getItem(LOCAL_STORAGE_VARIABLES.USER_DETAILS);
    }

    toogle(event: Event) {
        this._sidebarService.toggleSideNav.next();

        const dataTableElems = document.querySelectorAll('.hs-board-content');
        for (let i = 0, len = dataTableElems.length; i < len; i++) {
            const myClass = document.getElementsByClassName('hs-sidebar-open');
            if (myClass.length > 0) {
                dataTableElems[i].classList.add('hs-ml-260');
                dataTableElems[i].classList.remove('hs-ml-60');
            } else {
                dataTableElems[i].classList.add('hs-ml-60');
                dataTableElems[i].classList.remove('hs-ml-260');
            }
        }
    }

    logout() {
        this.spinnerFlag = true;
        const userDetails = this._localStorageService.getItem(LOCAL_STORAGE_VARIABLES.USER_DETAILS);
        this._sidebarService.logout(userDetails.userId)
            .subscribe((res) => {
                this.spinnerFlag = false;
                if (res.status === '200') {
                    this._localStorageService.clearAll();
                    this._router.navigate(['login']);
                } else {
                    this.toastr.error(res.message);
                }
            }, (err) => {
                this.spinnerFlag = false;
                this.toastr.error(err);
            });

    }
}
