import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-exist-dialog',
  templateUrl: './patient-exist-dialog.component.html',
  styleUrls: ['./patient-exist-dialog.component.css']
})
export class PatientExistDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PatientExistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close('close')
  }

  proceed() {
    this.dialogRef.close('proceed')
  }
}
