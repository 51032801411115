import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NotFoundModule } from './not-found/not-found.module';
import { HttpLoaderComponent } from './components/http-loader/http-loader.component';
import { AcknowledgeDialogComponent } from './dialogs/acknowledge-dialog/acknowledge-dialog.component';
import { AdditionalCommentDialogComponent } from './dialogs/additional-comment-dialog/additional-comment-dialog.component';
import { PatientExistDialogComponent } from './dialogs/patient-exist-dialog/patient-exist-dialog.component';
import { DuplicateOrderDialogComponent } from './dialogs/duplicate-order-dialog/duplicate-order-dialog.component';
import { FlagAddressDialogComponent } from './dialogs/flag-address-dialog/flag-address-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NotFoundModule,
        NgbModule
    ],
    declarations: [HttpLoaderComponent, AdditionalCommentDialogComponent, AcknowledgeDialogComponent, PatientExistDialogComponent, DuplicateOrderDialogComponent, FlagAddressDialogComponent],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HttpLoaderComponent
    ],
    entryComponents: [],
    providers: []
})
export class SharedModule { }
