import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from './../constants/api-endpoints.constant';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private _apiService: ApiService
  ) { }

  getOrders(size, pageNumber) {
    const url = `${API_ENDPOINTS.SALES.OREDR}?offset=${size}&page=${pageNumber}`;
    return this._apiService.get(url);
  }

  createOrder(params) {
    const url = `${API_ENDPOINTS.SALES.OREDR}`;
    return this._apiService.post(url, params);
  }

  updateOrder(params) {
    const url = `${API_ENDPOINTS.SALES.UPDATE_ORDER}`;
    return this._apiService.post(url, params);
  }

  deleteOrder(orderId, reason) {
    const url = `${API_ENDPOINTS.SALES.CANCEL_ORDER}/${orderId}`;
    return this._apiService.put(url, { reason });
  }

  getOrderById(orderId) {
    const url = `${API_ENDPOINTS.SALES.OREDR}/${orderId}`;
    return this._apiService.get(url);
  }

  getInvoices(size, pageNumber) {
    const url = `${API_ENDPOINTS.SALES.INVOICE}?offset=${size}&page=${pageNumber}`;
    return this._apiService.get(url);
  }

  updateInvoicePaymentType(params) {
    const url = `${API_ENDPOINTS.SALES.INVOICE_PAYMENT_TYPE}`;
    return this._apiService.post(url, params);
  }

  getInvoiceById(invoiceId) {
    const url = `${API_ENDPOINTS.SALES.INVOICE_DETAILS}/${invoiceId}`;
    return this._apiService.get(url);
  }

  downloadInvoiceByIdAndType(invoiceId, requestType) {
    const url = `${API_ENDPOINTS.SALES.INVOICE_DOWNLOAD}/${invoiceId}?reportType=${requestType}`;
    return this._apiService.get(url);
  }

  triggerOnDemandInvoice(onDemandRunMonth, onDemandRunYear) {
    const url = `${API_ENDPOINTS.SALES.ON_DEMAND_INVOICE}?invoiceMonth=${onDemandRunMonth}&invoiceYear=${onDemandRunYear}`;
    return this._apiService.get(url);
  }

  getDataInvoice(size, pageNumber, searchParams) {
    let url = searchParams ?
      `${API_ENDPOINTS.SALES.INVOICE}?offset=${size}&page=${pageNumber}&agencyId=${searchParams}`
      : `${API_ENDPOINTS.SALES.INVOICE}?offset=${size}&page=${pageNumber}`;
    // if (agencyId) {
    //     url += `&agencyId=${agencyId}`
    // }
    console.log('URL', url)
    return this._apiService.get(url);
  }
}
