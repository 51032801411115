export const REGEX_PATTERNS = {
    EMAIL: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*_()#^?&])[A-Za-z\d@$!%*_()#^?&]{8,}$/,
    MOBILE: /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    PRICE: /^\d+(,\d{3})*(\.\d{1,2})?$/,
    ZIPCODE: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    PHONENUMBER: /^([0-9]{10,12})$/,
    NAME: /^[a-zA-Z]+$/,
    NUMBER: /^-?([0-9]*)?$/,
    WHITESPACE: /^\S{1,}$/,
    DATE: /^((0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])[-](19|20)?[0-9]{2})*$/,
};
