import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';

@Injectable()
export class PatientsService {

    constructor(
        private _apiService: ApiService,
    ) { }

    addPatients(params) {
        const url = `${API_ENDPOINTS.LOGIN}`;
        return this._apiService.post(url, params);
    }

    addPatient(params) {
        const url = `${API_ENDPOINTS.PATIENT.PATIENT}`;
        return this._apiService.post(url, params);
    }

    validatePatient(params) {
        const url = `${API_ENDPOINTS.PATIENT.VALIDATE_PATIENT}`;
        return this._apiService.post(url, params);
    }

    getData(size, pageNumber, agencyId, searchParams, status) {
        let url = searchParams ?
            `${API_ENDPOINTS.PATIENT.PATIENT}?offset=${size}&page=${pageNumber}&status=${status}&searchString=${searchParams}`
            : `${API_ENDPOINTS.PATIENT.PATIENT}?offset=${size}&page=${pageNumber}&status=${status}`;
        if (agencyId) {
            url += `&agencyId=${agencyId}`
        }
        return this._apiService.get(url);
    }

    getPatientData(id) {
        const url = `${API_ENDPOINTS.PATIENT.PATIENT}/${id}`;
        return this._apiService.get(url);
    }

    getActivePatientsCount() {
        const url = `${API_ENDPOINTS.PATIENT.ACTIVE_PATIENT_COUNT}`;
        return this._apiService.get(url);
    }

    getActiveHomeHealthPatientsCount() {
        const url = `${API_ENDPOINTS.PATIENT.ACTIVE_HOME_HEALTH_PATIENT_COUNT}`;
        return this._apiService.get(url);
    }

    deleteData(id) {
        const url = `${API_ENDPOINTS.PATIENT.PATIENT}/${id}`;
        return this._apiService.delete(url);
    }

    getPatientsListByEquipment(id) {
        const url = `${API_ENDPOINTS.PATIENT.EQUIPMENT_BY_PATIENT}/${id}`;
        return this._apiService.get(url);
    }

    getOrderHistoryById(id, size, pageNumber) {
        const url = `${API_ENDPOINTS.PATIENT.ORDER_HISTORY}/${id}?offset=${size}&page=${pageNumber}`;
        return this._apiService.get(url);
    }

    validateAddress(address, city,zipCode) {
        const url = `${API_ENDPOINTS.ORDER.VALIDATE_ADDRESS}?address=${address}&city=${city}&zipCode=${zipCode}`;
        return this._apiService.get(url);
      }
}
