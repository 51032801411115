// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const BACKEND_URI: string = "http://localhost";
const BACKEND_PORT: number = 9091;
export const environment = {
  production: false,
  // url: 'http://ec2-3-18-212-145.us-east-2.compute.amazonaws.com:8080/hospiceservices/'
  // url: 'http://ec2-3-17-72-129.us-east-2.compute.amazonaws.com:8080/hospiceservices/'
  //url: `http://${BACKEND_URI}:${BACKEND_PORT}`
  url: 'https://stg-api.h3sdme.com/'
  // url: 'http://localhost:9091/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
