import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import 'rxjs';

import { API_ENDPOINTS } from '../constants';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    public toggleSideNav = new Subject<string>();

    private sideMenuListSource = new BehaviorSubject<any>(null);
    sideMenuListChange$ = this.sideMenuListSource.asObservable();

    constructor(
        private _apiService: ApiService
    ) { }

    watchToggleSideNav(): Observable<string> {
        return this.toggleSideNav.asObservable();
    }

    logout(id) {
        const url = `${API_ENDPOINTS.USER_MANAGEMENT}/${id}`;
        return this._apiService.get(url);
    }
}
