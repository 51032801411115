import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';

import { environment } from './../../../environments/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { ROLES } from '../constants';

@Injectable()
export class UtilsService {
    constructor(private _datePipe: DatePipe) { }

    encode(value) {
        return btoa(encodeURIComponent(value));
    }

    decode(value) {
        return decodeURIComponent(atob(value));
    }

    transformToSnakeCase(data) {
        let newData;

        if (data instanceof File || data instanceof FormData) { // file object -> transformation is not needed
            newData = data;
        } else {
            if (data && _.isObject(data) && !Array.isArray(data)) { // object
                newData = {};
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const newKey = _.snakeCase(key);
                        newData[newKey] = data[key];
                        if (data[key] && _.isObject(data[key]) && !Array.isArray(data[key])) { // object
                            newData[newKey] = this.transformToSnakeCase(data[key]);
                        } else if (data[key] && Array.isArray(data[key])) { // array
                            newData[newKey] = this.transformToSnakeCase(data[key]);
                        }
                    }
                }
            } else if (data && _.isObject(data) && Array.isArray(data)) { // array
                newData = [];
                for (const value of data) {
                    if (_.isObject(value) || Array.isArray(value)) { // array or object
                        newData.push(this.transformToSnakeCase(value));
                    } else { // any value except array and object
                        newData.push(value);
                    }
                }
            }
        }

        return newData;
    }

    transformToCamelCase(data) {
        let newData;
        if (data && _.isObject(data) && !Array.isArray(data)) { // object
            newData = {};
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const newKey = _.camelCase(key);
                    newData[newKey] = data[key];
                    if (data[key] && _.isObject(data[key]) && !Array.isArray(data[key])) { // object
                        newData[newKey] = this.transformToCamelCase(data[key]);
                    } else if (data[key] && Array.isArray(data[key])) { // array
                        newData[newKey] = this.transformToCamelCase(data[key]);
                    }
                }
            }
        } else if (data && _.isObject(data) && Array.isArray(data)) { // array
            newData = [];
            for (const value of data) {
                if (_.isObject(value) || Array.isArray(value)) { // array or object
                    newData.push(this.transformToCamelCase(value));
                } else { // any value except array and object
                    newData.push(value);
                }
            }
        }

        return newData;
    }

    retrieveFileNameFromUrl(url) {
        const splittedUrlArr = url.split('?');
        let fileName = '';

        if (splittedUrlArr && splittedUrlArr.length) {
            const fileUrl = splittedUrlArr[0];
            const splittedFileUrlArr = fileUrl.split('/');
            if (splittedFileUrlArr && splittedFileUrlArr.length) {
                fileName = splittedFileUrlArr[splittedFileUrlArr.length - 1];
            }
        }

        return fileName;
    }

    getBaseUrlForTwitter() {
        let baseUrl = this.getBaseUrl();

        // replace https with http (this hack is needed until backend resolves https issue for twitter)
        // @TODO('Sagar') -> remove https replacement logic once backend solves the issue
        baseUrl = baseUrl.replace(/https/g, 'http');

        return baseUrl;
    }

    getBaseUrl() {
        const envUrl = `${environment.url}`;

        const newUrl = envUrl.substr(0, envUrl.length - 7);

        return newUrl;
    }

    dataTableSearch(arrayData, keys, val) {
        const tempArray = arrayData.filter((data) => {
            if (!val) {
                return true;
            }
            for (const key of keys) {
                if (data[key] && data[key].toString().toLowerCase().indexOf(val) > -1) {
                    return true;
                }
            }
            return false;
        });
        return tempArray;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
        });
        return formGroup;
    }

    calculateTimeDiff(date) {
        if(date !== undefined) {
            if(new Date().getDate() <  new Date(date).getDate() && new Date().getMonth() <=  new Date(date).getMonth() && new Date().getFullYear() <=  new Date(date).getFullYear()) {
                return 0;
            } else {
                //@ts-ignore
                return Math.abs(new Date() - new Date(date)) / 36e5;
            }
        }
        return 0;
    }

    calculateTimeDiffForTimeRange(date, timeRange) {
        if(date !== undefined) {

            let calculatedTime = 9;

            if(timeRange === '9AM to 11AM') {
                calculatedTime = 11;
            } else if(timeRange === '11AM to 1PM') {
                calculatedTime = 13;
            } else if(timeRange === '1PM to 3PM') {
                calculatedTime = 15;
            } else if(timeRange === '3PM to 7PM') {
                calculatedTime = 19;
            } else if(timeRange === '7PM to 9PM') {
              calculatedTime = 21;
            }
             let calculateDate = new Date(date);
             calculateDate.setHours(calculatedTime);
             if(new Date().getDate() <  new Date(calculateDate).getDate() && new Date().getMonth() <=  new Date(calculateDate).getMonth() && new Date().getFullYear() <=  new Date(calculateDate).getFullYear()) {
                 return 0;
             } else {
             //@ts-ignore
                 return Math.abs(calculateDate - new Date()) / 36e5;
             }
        }
        return 0;
    }

    changeDateFormat(date) {
        if (date && typeof date === 'string') {
            const d = date.split('-');
            return { year: +d[2], month: +d[0], day: +d[1] };
        } else if (date && typeof date === 'object') {
            return `${date.day}-${date.month}-${date.year}`;
        }
    }

    changeDateFormatOfString(date) {
        if (date && typeof date === 'string') {
            const d = date.split('-');
            return { year: +d[0], month: +d[1], day: +d[2] };
        }
     }

    changeDateFormatDisplay(date) {
        if (date.day <= 9 && date.month <= 9) {
            return `0${date.month}-0${date.day}-${date.year}`;
        }
        if (date.month <= 9) {
            return `0${date.month}-${date.day}-${date.year}`;
        }
        if (date.day <= 9) {
            return `${date.month}-0${date.day}-${date.year}`;
        }
        return `${date.month}-${date.day}-${date.year}`;
    }

     base64ToArrayBuffer(base64) {
         var binaryString = window.atob(base64);
         var binaryLen = binaryString.length;
         var bytes = new Uint8Array(binaryLen);
         for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
         }
         return bytes;
      }

    formatDate(date) {
             const year = date.split('-')[2];
             let month = date.split('-')[1];
             let day = date.split('-')[0];
             if (month <= 9) {
                 month = '0' + date.split('-')[1];
                 const number = month.substring(0, 2);
                 if (number === '00') {
                     month = date.split('-')[1];
                 }
             }
             if (day <= 9) {
                 day = '0' + date.split('-')[0];
                 const number = day.substring(0, 2);
                 if (number === '00') {
                     day = date.split('-')[0];
                 }
             }

             const fullDate = day + '-' + month + '-' + year;
             return fullDate;
         }


    formatDateForString(date) {
        const year = date.year;
        let month = date.month;
        let day = date.day;
        if (month <= 9) {
            month = '0' + date.month;
            const number = month.substring(0, 2);
            if (number === '00') {
                month = date.month;
            }
        }
        if (day <= 9) {
            day = '0' + date.day;
            const number = day.substring(0, 2);
            if (number === '00') {
                day = date.day;
            }
        }

        const fullDate = day + '-' + month + '-' + year;
        return fullDate;
    }


    setSidebarMargin() {
        const dataTableElems = document.querySelectorAll('.hs-board-content');
        for (let i = 0, len = dataTableElems.length; i < len; i++) {
            const myClass = document.getElementsByClassName('hs-sidebar-open');
            if (myClass.length > 0) {
                dataTableElems[i].classList.add('hs-ml-60');
                dataTableElems[i].classList.remove('hs-ml-260');
            } else {
                dataTableElems[i].classList.add('hs-ml-260');
                dataTableElems[i].classList.remove('hs-ml-60');

            }
        }
    }
}
