import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { Login } from '../../core/models/auth';
import { LITERALS, LOCAL_STORAGE_VARIABLES, REGEX_PATTERNS } from '../../core/constants';
import { JwtService, LocalStorageService, AuthService } from 'src/app/core/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginFormErrors = LITERALS.AUTH.ERROR_MESSAGES;
    loginForm: FormGroup;
    spinnerFlag = false;

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        private _authService: AuthService,
        private _jwtService: JwtService,
        private _localStorageService: LocalStorageService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.loginForm = this._fb.group({
            username: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.WHITESPACE)]],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
        });

        if (this._authService.isLoggednIn()) {
            this._router.navigate(['/current-order']);
        }
    }

    onLoginSubmit(form) {
        this.spinnerFlag = true;
        const params: Login = { ...form.value };
        this._authService.login(params)
            .subscribe((res: any) => {
                if (res.status === '200') {
                    this._jwtService.setToken(res['data'].accessToken);
                    this._localStorageService.setItem(LOCAL_STORAGE_VARIABLES.USER_DETAILS, res['data']);
                    this._router.navigate(['/current-order']);
                    this.toastr.success('Logged-in Successfully');
                    this.spinnerFlag = false;
                } else {
                    this.toastr.error(res.message);
                    this.spinnerFlag = false;
                }
            }, (error) => {
                this.spinnerFlag = false;
                this.toastr.error(error);
            });
    }
}
