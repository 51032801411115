import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, trigger, state, style, transition } from '@angular/animations';
import * as $ from 'jquery';

import { AuthService } from 'src/app/core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    animations: [
        trigger('move', [
            state('in', style({ transform: 'translate3d(0, 0, 0)' })),
            state('out', style({ transform: 'translateX(0)' })),
            transition('in => out', animate('12s linear'))
        ])
    ]
})

export class LandingPageComponent implements OnInit, AfterViewInit {

    state = 'in';
    cityData;
    cityMapUrl;
    imgSrc = 'assets/images/android-app.png';

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.inItCity();
        this.onChangeMap('Austin');
        $(window).scroll(function () {
            if ($(this).scrollTop() > 250) {
                $('.hs-navbar').addClass('header-fixed');
            } else {
                $('.hs-navbar').removeClass('header-fixed');
            }
        });

        $(document).ready(function () {
            $(window).scroll(function () {
                const scrollDistance = $(window).scrollTop();
                $('.page-section').each(function (i) {
                    if (($(this).position().top - 110) <= scrollDistance) {
                        $('.nav-item a').removeClass('active');
                        $('.nav-item a').eq(i).addClass('active');
                    }
                });
            }).scroll();
            $('.hs-cover-menubar').click(function () {
                $('.overlay').show();
                $('.hs-land-link').addClass('show');
            });
            $('.hs-close').click(function () {
                $('#navbarSupportedContent').removeClass('show');
                $('.overlay').hide();
            });
            $('.nav-item').on('click', function (e) {
                $('li.nav-item a').removeClass('active');
                $(this).find('a').addClass('active');
            });
        });

        if (this._authService.isLoggednIn()) {
            this._router.navigate(['/current-order']);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.state = 'out';
        }, 0);
    }

    onEnd(event) {
        this.state = 'in';
        if (event.toState === 'in') {
            setTimeout(() => {
                this.state = 'out';
            }, 0);
        }
    }

    submitContact() {
        this.toastr.success('Thanks for the Registration. We will get back to you.');
    }

    inItCity() {
        this.cityData = [
            {
                city: 'Austin',
                Latitude: 30.267153,
                Longitude: -97.743057,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d442950.38952126045!2d
                -96.59344239151062!3d29.848410702508378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13
                .1!3m3!1m2!1s0x864144a3a5792851%3A0x5b892e7b5863c5d9!2sAustin+County%2C
                +TX%2C+USA!5e0!3m2!1sen!2sin!4v1560516822228!5m2!1sen!2sin`
            }, {
                city: 'Brazoria',
                Latitude: 29.048830,
                Longitude: -95.570389,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d445878.1156017338!2d
                -95.74613168543425!3d29.181677440967068!2m3!1f0!2f0!3f0!3m2!1i1024
                !2i768!4f13.1!3m3!1m2!1s0x8640e906929201c7%3A0xe4471403a3d0c835!2sBrazoria+County%2C
                +TX%2C+USA!5e0!3m2!1sen!2sin!4v1558702695538!5m2!1sen!2sin`
            }, {
                city: 'Galveston',
                Latitude: 29.301348,
                Longitude: -94.797699,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d445654.9531838803!2d-95.
                15350405592424!3d29.232985582957912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x863f59c8c4059259
                %3A0xe58b03c9b9eaecc1!2sGalveston%2C+TX%2C+USA!5e0!3m2!1sen!2sin!4v1559046310884!5m2!1sen!2sin`
            }, {
                city: 'Harris',
                Latitude: 45.586182,
                Longitude: -92.975311,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d443015.74426693044!2d-95.71549661051795!3d29.83367535859551
                !2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640cc8afd7f30f5%3A0x2eaf5277b2bbd2da
                !2sHarris+County%2C+TX%2C+USA!5e0!3m2!1sen!2sin!4v1558702606082!5m2!1sen!2sin`
            }, {
                city: 'Fort Bend',
                Latitude: 29.587410,
                Longitude: -95.476868,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d222188.16916656416!2d-95.89694139355656!3d29.52538970738769
                !2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640db32bee0c427%3A0xb9f8633749b5ec55
                !2sFort+Bend+County%2C+TX%2C+USA!5e0!3m2!1sen!2sin!4v1558702516397!5m2!1sen!2sin`
            }, {
                city: 'Montgomery',
                Latitude: 32.379223,
                Longitude: -86.307739,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27534.112676157747!2d-95.71891549123463!3d30.38605627685366
                !2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8647202fe1e99245%3A0xefd99d2d29a7898c
                !2sMontgomery%2C+TX%2C+USA!5e0!3m2!1sen!2sin!4v1558702449294!5m2!1sen!2sin`
            }, {
                city: 'Liberty',
                Latitude: 39.244740,
                Longitude: -94.418457,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55256.55898828108!2d-94.80994216522495!3d30.050197388455878
                !2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x863f54155b247e39%3A0xe8edfe173a8c42d4
                !2sLiberty%2C+TX%2C+USA!5e0!3m2!1sen!2sin!4v1558702400518!5m2!1sen!2sin`
            }, {
                city: 'Wharton',
                Latitude: 29.311638,
                Longitude: -96.102737,
                url: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55669.5208506155!2d-96.14618466876007!3d29.301552460086207
                !2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864174e7ababbe6b%3A0x635e4c659d95a1ab
                !2sWharton%2C+TX+77488%2C+USA!5e0!3m2!1sen!2sin!4v1558702157320!5m2!1sen!2sin`
            }
        ];
    }

    onChangeMap(city) {
        for (let i = 0, len = this.cityData.length; i < len; i++) {
            if (city === this.cityData[i].city) {
                this.cityMapUrl = this.cityData[i].url;
            }
        }
    }

    scrollTo(id) {
        const elem = document.getElementById(id);
        if (elem) {
            const offsetTop = elem.offsetTop;
            window.scrollTo(0, offsetTop - 110);
        }
    }
}
