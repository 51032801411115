import { Component } from '@angular/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class FullLayoutComponent {
    constructor() { }
}
