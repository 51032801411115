import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {
  constructor(
    private _apiService: ApiService
  ) { }

  uploadPickupTicket(params) {
    const url = `${API_ENDPOINTS.ORDER.ADD_PICKUP_TICKET}`;
    return this._apiService.post(url, params);
  }

  uploadPickupTicketNew(params, orderId, userId) {
    const url = `${API_ENDPOINTS.ORDER.UPLOAD_TICKET}?orderId=${orderId}&userId=${userId}`;
    return this._apiService.mulitipartForm(url, params);
  }

  addAdditionalComments(params) {
    const url = `${API_ENDPOINTS.ORDER.ADD_ADDITIONAL_COMMENTS}`;
    return this._apiService.post(url, params);
  }

  fetchAllPickupTickets(params) {
    const url = `${API_ENDPOINTS.ORDER.ORDER}/` + params + '/pickupTicket';
    return this._apiService.get(url, params);
  }

  fetchAdditionalComments(params) {
    const url = `${API_ENDPOINTS.ORDER.ORDER}/` + params + '/additionalComment';
    return this._apiService.get(url, params);
  }

  deletePickupTicket(params) {
    const url = `${API_ENDPOINTS.ORDER.PICKUP_TICKET}/` + params + '/_delete';
    return this._apiService.delete(url, params);
  }

  deleteAdditionalComments(params) {
    const url = `${API_ENDPOINTS.ORDER.ADDITIONAL_COMMENTS}/` + params + '/_delete';
    return this._apiService.delete(url, params);
  }

  duplicateOrderValidate(patientId, equipmentId) {
    const url = `${API_ENDPOINTS.ORDER.VALIDATE_ORDER}?patientId=${patientId}&equipmentId=${equipmentId}`;
    return this._apiService.get(url);
  }

}
