import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(
    private _apiService: ApiService
  ) { }

  scanEquipment(params) {
       const url = `${API_ENDPOINTS.EQUIPMENT.SCAN}`;
       return this._apiService.post(url, params);
    }

   fetchSerialNumberHistory(size, pageNumber, searchString) {
       const url = searchString ? `${API_ENDPOINTS.EQUIPMENT.SEARCH_SERIAL_NUMBER_HISTORY}?offset=${size}&page=${pageNumber}&serialNumber=${searchString}`
           : `${API_ENDPOINTS.EQUIPMENT.SEARCH_SERIAL_NUMBER_HISTORY}?offset=${size}&page=${pageNumber}`;
       return this._apiService.get(url);
   }
}