import { Injectable } from '@angular/core';

import { UtilsService } from './utils.service';

@Injectable()
export class LocalStorageService {

    constructor(
        private _utilService: UtilsService
    ) { }

    getItem(key) {
        return window.localStorage.getItem(key) ? JSON.parse(this._utilService.decode(window.localStorage.getItem(key))) : null;
    }

    setItem(key, value) {
        window.localStorage[key] = this._utilService.encode(JSON.stringify(value));
        return this.getItem(key);
    }

    removeItem(key) {
        window.localStorage.removeItem(key);
    }

    clearAll() {
        window.localStorage.clear();
    }
}

