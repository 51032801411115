export const LOCAL_STORAGE_VARIABLES = {
    USER_EMAIL: 'userEmail',
    USER_TYPE: 'usertype',
    TOKEN: 'token',
    USER_DETAILS: 'userDetails',
    EXPIRES_IN: 'expiresIn',
    ACTIVE_SIGN_IN_STEP: 'activeSignInStep',
    CAMPAIGN_OBJ: 'campaignObj',
    CART_DETAILS: 'cartDetails',
    SELECTED_EQUIPMENTS_FOR_ORDER: 'selectedEquipmentsForOrder'
};