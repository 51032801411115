import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    constructor(
        private _apiService: ApiService
    ) { }

    addUserData(params) {
        const url = `${API_ENDPOINTS.USER_MANAGEMENT}`;
        return this._apiService.post(url, params);
    }

    deleteUser(id) {
        const url = `${API_ENDPOINTS.USER_MANAGEMENT}/${id}`;
        return this._apiService.delete(url);
    }

    editRoleRight() {
        const url = `${API_ENDPOINTS.USER_MANAGEMENT}`;
        return this._apiService.post(url);
    }

}
