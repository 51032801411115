import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-flag-address-dialog',
  templateUrl: './flag-address-dialog.component.html',
  styleUrls: ['./flag-address-dialog.component.css']
})
export class FlagAddressDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FlagAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close('close')
  }

  proceed() {
    this.dialogRef.close('close')
  }

}
