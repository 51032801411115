import { MODULES } from './module.constant';

export const ROUTES = [
    {
        moduleName: MODULES.CURRENT_ORDER,
        routes: [
            '/current-order'
        ]
    },
    {
        moduleName: MODULES.USER,
        routes: [
            '/user-management'
        ]
    },
    {
        moduleName: MODULES.ROLES_RIGHTS,
        routes: [
            '/roles-rights'
        ]
    },
    {
        moduleName: MODULES.PRODUCTS,
        routes: [
            '/inventory'
        ]
    },
    {
        moduleName: MODULES.WARE_HOURSE,
        routes: [
            '/inventory'
        ]
    },
    {
        moduleName: MODULES.ORDERS,
        routes: [
            '/sales'
        ]
    },
    {
        moduleName: MODULES.INVOICES,
        routes: [
            '/sales'
        ]
    },
    {
        moduleName: MODULES.PATIENTS,
        routes: [
            '/create-order',
            '/patients-list'
        ]
    },
    {
        moduleName: MODULES.AGENCY,
        routes: [
            '/agency-registration'
        ]
    },
    {
        moduleName: MODULES.FACILITY,
        routes: [
            '/facility'
        ]
    },
    {
        moduleName: MODULES.EQUIPMENT,
        routes: [
            '/equipment-search'
        ]
    },
    {
        moduleName: MODULES.REPORTS,
        routes: [
            '/reports'
        ]
    }, {
        moduleName: MODULES.RESOURCES,
        routes: [
            '/resources'
        ]
    }
];
